<template>
  <div class="card">

    <Breadcrumb :model="items"/>

    <div class="p-p-3">
      <div class="p-fluid p-grid p-mt-3">
        <div class=" p-col-6">
          <label style="text-align: left; margin: 0">主题:</label>
          <InputText @blur="change" v-model="sendMessage.title" class="p-mt-2" placeholder="输入主题"/>
          <span class="change" v-show="flag">您输入格式有误，请重新输入</span>
        </div>
        <!--      <div class="p-col-12 ">
                    <div>
                        <label>消息类型:</label>
                        <InputText @blur="change1" v-model="sendMessage.messageType" class="p-mt-2" placeholder="消息类型"/>
                        <span class="change1" v-show="flag1">您输入格式有误，请重新输入</span>
                    </div>
                  </div>-->
        <div class="p-col-6" style="display: flex;align-items: center">
          <label class="p-mt-5" style="text-align: left; margin: 0">是否为全员消息:</label>
          <InputSwitch class="p-mt-5" v-model="sendMessage.allUser"/>
        </div>
        <div class="p-col-6">
          <label style="text-align: left; margin: 0">所属行业:</label>
          <MultiSelect class="p-mt-2" v-model="sendMessage.industryList" :options="industry" optionLabel="name"
                       option-value="name" @input="getUserList"
                       placeholder="所属行业" display="chip"/>
        </div>

        <div class=" p-col-12">

          <label style="text-align: left; margin: 0">消息简介:</label>
          <InputText v-model="sendMessage.brief" class="p-mt-2" placeholder="简介"/>

        </div>

      </div>
      <div class=" p-fluid">
        <label style="text-align: left; margin: 0">内容：</label>
        <my-editor :value="sendMessage.content" v-on:input="setContent"></my-editor>
<!--        <Editor class="p-mt-2" width="calc(100% - 130px)" v-model="sendMessage.content" editorStyle="height: 320px"/>-->
      </div>
      <div class="p-grid p-col-12 p-mt-6 p-jc-around">
        <!--      <Button @click="cancel()" label="取消编辑" class="p-button-outlined p-button-secondary"/>-->
        <Button @click="submit(1)" label="保存至草稿箱" class="p-mr-2" icon="pi pi-fw pi-save"/>
        <Button @click="submit(2)" label="确认发布" class="p-mr-2" icon="pi pi-fw pi-send"/>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>
import MyEditor from "@/views/backend/myEditor";
export default {
  name: "",
  components: {MyEditor},
  data() {
    return {
      flag: false,
      flag1: false,
      id: null,
      selectedIndustry: null,
      user: null,
      sendMessage: {
        content: null,
        brief: null,
        industryList: [],
        title: null,
        state: null,
        allUser: false
      },
      industry: [],
      sendingPeople: [],
      items: [
        {label: '消息推送', to: '/SendMessage'},
        {label: '发送消息', to: '/SendMessage'}
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    setContent(val){
      this.sendMessage.content=val
    },
    init() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industry = resList;
      })
    },
    change(e) {
      let a = e.target.value.replace(/^ +| +$/g, '')
      // console.log(a, e.target.value)
      if (a != e.target.value) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    change1(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      // console.log(a, e.target.value)
      if (b != e.target.value) {
        this.flag1 = true;
      } else {
        this.flag1 = false;
      }
    },
    getUserList(e) {
      this.$http.get('/send/getUserList?name=' + e.value).then((res) => {
        console.log(res.data)
        this.sendingPeople = res.data
      })
    },
    submit(e) {
      console.log(this.sendMessage.content)
      if (e == 1) {
        this.sendMessage.state = 1
      } else {
        this.sendMessage.state = 0
      }

      if (this.sendMessage.allUser) {
        this.sendMessage.allUser = 1
      } else {
        this.sendMessage.allUser = 0
      }

      if (this.sendMessage.state == 0 && this.sendMessage.title == null | this.sendMessage.title == ""
          | this.sendMessage.brief == null | this.sendMessage.brief == ""
          | this.sendMessage.content == null | this.sendMessage.content == ""
          | this.sendMessage.industryList == null | this.sendMessage.industryList == "") {
        this.$toast.add({severity: 'error', summary: '错误信息', detail: '您有未输入的信息，请重新输入', life: 3000})
      } else {
        this.$http.post('/send/message', this.sendMessage).then(() => {
          let me = this
          this.$toast.add({
            severity: 'success', summary: '成功', life: 3000
          })
          setTimeout(function () {
            me.$router.push('/messageList')
          }, 1000)


        })
      }
    },
    /*cancel() {
      this.$router.push('/')
    }*/
  }
}
</script>

<style scoped>
.change {
  color: red;
}

.change1 {
  color: red;
}

label {
  display: inline-block;
  width: 120px;
  text-align: right;
  margin-right: 10px;
}

</style>
